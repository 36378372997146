/*******************/
// Theme Fonts
/********************/
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// $font-family-sans-serif: "Poppins";

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight:275;
  font-display: swap
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight:500;
  font-display: swap
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight:300;
  font-display: swap
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight:400;
  font-display: swap
}


$font-family-sans-serif: 'Poppins', sans-serif;
/*******************/
// Theme Colors
/********************/
$primary: #1E4E9D;
$info: #3699ff;
$danger: #f64e60;
$success: #0bb7af;
$warning: #ED6E1C;
$dark: #343a40;
$light: #eaf2fb;
$secondary: #35363b;
$lightgreen:#81A7BA;
$transparent:transparent;
$degrade:linear-gradient(90deg, $warning 0%, #1E4E9D 100%);
$gray: #575C76;
$black:#000;
$purple:#A6ABC8;



$large:"";
$x:"1.5625rem"; //25px
$small:"";



$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-primary": #d6e4f3,
  "light-success": #d5f3f2,
  "light-info": #d3edfa,
  "light-warning": #f8ecdc,
  "light-danger": #f8dddd,
  "gray": #878680,
  "purple":#A6ABC8,
);
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eef5f9 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757e85 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1e2a35 !default;
$black: #000 !default;

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 2;

/*******************/
// Theme body bg & text color
/********************/
$body-bg: transparent !default;
$body-color: $black !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.95 !default; // 12.025px
/*******************/
// Theme common variable
/********************/
$border-radius: 0.25rem !default; //4px
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);
$headings-font-weight: 600 !default;

$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$grid-gutter-width: 1.85rem !default;

$input-bg: $white !default;
/*******************/
// Theme btn
/********************/
$btn-focus-width: 0px;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 260px;
$sidebarColor: $white;

$imgBorderRadius: 4vh;