.custom-table-card { 
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 20px 0;
  
    .custom-table-title {
      background-color: #f2f6fa;
      color: #333;
      font-weight: bold;
      padding: 16px;
      border-bottom: 1px solid #e0e0e0;
    }
  
    .custom-table-body {
      padding: 0;
    }
  
    .custom-table {
      width: 100%;
      border-collapse: collapse;
      margin: 0;
  
      thead {
        background-color: #f2f6fa;
  
        th {
          color: #1E4E9D;
          font-weight: bold;
          padding: 16px;
          text-align: left;
        }
      }
  
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f2f6fa;
          }
  
          td {
            padding: 16px;
            text-align: left;
            border-bottom: 1px solid #e0e0e0;
          }
  
          &:hover {
            background-color: lighten(#f2f6fa, 5%);
          }
        }
      }
    }
  }
  .table-bordered {
    > :not(caption) > * {
      border-width: 0 0;
  
      // stylelint-disable-next-line selector-max-universal
      > * {
        border-width: 0 0;
      }
    }
  }
  .table > :not(:first-child) {
    border-top: none;
}



.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Allows for smooth scrolling on iOS devices */
}

.custom-table-card {
  margin-bottom: 1rem;
}

.custom-table th, .custom-table td {
  white-space: nowrap; /* Prevents text wrapping */
}