.languageSwitcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .globe {
    font-size: 1.2rem;
  }
  
  .select {
      padding: 0.5rem;
      border: none;
      color: #575C76;
      cursor: pointer;
  }
  .margin-block-start-40 {
    margin-block-start: 40%;
  }
  .margin-block-start-1 {
    margin-block-start: 1%;
  }
  
  .margin-block-start-30 {
    margin-block-start: 30%;
  }
  