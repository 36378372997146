/* ThumbRating.module.css */
.thumb-rating {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-left: 1rem;
  }
  
  .thumb-btn {
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .thumb-btn.selected {
    color: gold; /* Changer la couleur des icônes sélectionnées */
    transform: scale(1.2); /* Agrandir légèrement l'icône lorsqu'elle est sélectionnée */
  }
  
  .thumb-btn:hover {
    transform: scale(1.1); /* Effet de survol */
  }
  
  .thumb-btn:focus {
    outline: none;
  }
  