.pricesHeader {
  text-align: center;
  padding-left: 22%;
  padding-right: 21%;
}

.pricesContainer {
  margin: 30px;
  display: flex;
  justify-content: center;
}

.pricesBox {
  padding: 10px;
  margin: 25px;
}

.pricingTitle {
  font-family: Poppins;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 66px;
  margin: 20px;
  -webkit-background-clip: text;
  background-clip: text;
}

.pricingDesc {
  margin: 20px;
  font-size: 1.125rem;
  font-weight: 275;
  line-height: 30px;
  text-align: center;
}

.pricesHeader {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .pricing-section-page {
    padding-top: 25%;
  }

  .pricesContainer {
    margin: 10px;

  }

  .pricesHeader {

    padding-left: 10px;
    padding-right: 10px;
  }

  .pricingTitle {

    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    margin: 10px;


  }

  .pricingDesc {
    margin: 10px 0;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 275;
    line-height: 30px;
    text-align: center;

  }
}