.content-wrapper {
  padding-inline: 20px;
}
.pageWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.d-md-block.d-lg-flex {
  flex-grow: 1;
}

.contentArea {
  flex-grow: 1;
}

.footer {
  border-radius: 85% 85% 0 0 / 10%;
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(
    177.98deg,
    #1e4e9d -7.98%,
    #0493e3 135.61%
  ) !important;
  color: #fff;
  text-align: left;
  padding: 6vh 5%;
}
.footer-poweredby {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
  // margin-left: 20px;
}

.footer-section {
  flex: 1;
  // margin: 0 10px;
}

.logo-description {
  flex: 2;
  font-size: 0.875rem;
  font-weight: 300;
}

.nav-contact-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-contact { 
  margin-left: 20px;
}

.navs-container {
  margin: 0 10px;
}

.navitem-section {
  font-size: 0.875rem;
  margin: 0;
  font-weight: 200;
  margin-top: 1rem;
  cursor: pointer;
}

.icons-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  // margin-left: 20px;
  margin-top: 1rem;
  cursor: pointer;
}

.icon-wrapper {
  width: Hug (30.88px) px;
  height: Hug (30.89px) px;
  top: 35px;
  left: 150px;
  gap: 10px;
  opacity: 10px;
}

.footer-links {
  margin-left: 5%;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.footer-pages-items {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;
}
.footer-pages-link {

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
 }

.footer-pages-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.footer-contact-item{
   margin-right: 15px;
}
 @media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    left: 0 !important;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer-links {
    width: 100%;
    justify-content: flex-start;
  }
  .navs,
  .icons-container {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .navs-container,
  .icon-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  .navitem-section {
    font-size: 16px;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
  }
  .navs-items {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
  }
  .footer-links{
    flex-direction: column;
  }
  .footer-pages-link{
    padding-inline: 0.5rem;
  }
  .footer-pages{
    display: flex;
    flex-direction: row;
    justify-content: 0.5rem;
  }
  .footer-pages-items,.footer-contact{
    display: flex;
    
    flex-direction: row;
  }
  .footer .footer-pages-link.py-1{
    padding-inline: 0rem;
  }
  .footer-contact{
    margin-left: unset;
  }
  .footer-pages-title{
    display: flex;
    align-items: flex-end;
  }
  .footer-pages-items{
    padding-left: 0.5rem;
  }
  .footer-contact-item{
    margin-right: unset;
  }
  .footer-contact .navitem-section{
    padding-left: 0.5rem;
  }
  .footer .footer-section{
    margin-bottom: unset;
  }
}

.content {
  flex: 1;
}
.footer-container {
  // background-color: #f8f9fa;
  // padding: 20px;
  text-align: center;
}
.no-style-link {
  color: inherit;
  // text-decoration: unset;
  cursor: pointer;
}

@media(min-width:1200px){
 .pageWrapper{
  min-height: 87vh;
 } 
}

.a-href-no-style{
  text-decoration: none;
  font-size: 16px;
  font-weight: 200;
  color: white;
}
.img-icon-footer{
  vertical-align: text-top;
}
.text-style {
  white-space: nowrap;
}