 .logo-content-centred {
     text-align: center;
     padding-bottom: 2rem;
 }

 .text-primary {
     font-weight: 600 !important;
 }

 .password-container {
     position: relative;
     display: flex;
     align-items: center;
 }

 .password-toggle {
     background: none;
     border: none;
     cursor: pointer;
     position: absolute;
     right: 10px;
     top: 50%;
     transform: translateY(-50%);
 }

 .password-icon {
     cursor: pointer;
     width: 24px;
     height: 24px;
 }

 .form-description {
     display: flex !important;
     justify-content: center;

 }
