
  
  .sliding {
   
    animation: slideOver 2s forwards;
    animation-fill-mode: forwards; 
    
  }
 .img-fluid{
  z-index: 30;
  background-image: 
    linear-gradient(45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), 
    linear-gradient(-45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), 
    linear-gradient(45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%), 
    linear-gradient(-45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%);
background-size: 30px 30px; /* Smaller squares */
background-position: 0 0, 0 15px, 15px -15px, -15px 0; /* Adjust positioning for the new size */
background-repeat: repeat; /* Repeat in both directions */

  
 }
 .old-img{
  position: absolute;
  z-index: 0;
}
.result-img{
  animation: slideBegin 2s forwards;
  animation-fill-mode: forwards; 
}

  @keyframes slideOver {
    from {
      clip-path: inset(0 0 0 0);
  }
  to {
      clip-path: inset(0 0 0 100%);
  }
  }
  
  @keyframes slideBegin {
  from {
    
    background-image: none
}
to {
  background-image: 
  linear-gradient(45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), 
  linear-gradient(-45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), 
  linear-gradient(45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%), 
  linear-gradient(-45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%);
background-size: 30px 30px; /* Smaller squares */
background-position: 0 0, 0 15px, 15px -15px, -15px 0; /* Adjust positioning for the new size */
background-repeat: repeat; /* Repeat in both directions */
}
  }