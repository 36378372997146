.uploadContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 600px;
  border: 2px;
  padding: 2% 2% 4% 3%;
}

.upload-box {
  padding: 10%;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1vh;
  border: 3px dashed rgba(172, 172, 172, 0.205);
}

.upload-box p {
  margin-bottom: 0;
  color: #A6ABC8;

}

.image-placeholder {
  padding: 20px;
  margin-bottom: 20px;
  display: inline-block;
}

.controls-section {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.resize-controls {

  width: 80%;
  max-width: 320px;
  //min-width: 280px;
  padding: 15px;
}

.resize-header {
  display: flex;
  margin-bottom: 20px;
}

.resize-icon {
  font-size: 24px;
  margin-right: 10px;
}

.uploadButton {
  align-self: center;
  width: 50%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #1e4e9d;
  border-radius: 0.25rem;
  color: #1e4e9d;
  font-size: 16px;
  cursor: pointer;
}
.left-buttons {
  padding-block: 1rem;
  width: 1rem;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.add-bg-controls {
  max-width: 300px;
  min-width: 250px;
}
@media (max-width: 770px) {
  .controls-section {
    border: 0;
    border-top: 1px solid #ccc;
    border-left:unset;
  }
  .left-buttons {
    width:auto;
    padding-inline: unset!important;
  }
  .upload-box {
    width:100%;
    height: 70vh;
  }
  .result-img-box{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100vh;
  }
  .upload-section {
    width:100%
  }
  .upload-box button{
    margin-right: unset!important;
  }
}
@media (max-width: 768px) {
  .resize-controls {
    display: contents;
    width: 100%;
  }

  .uploadContainer {
    padding: 6% 6% 6% 6%;
    flex-direction: column;
    height: 120vh;
  }
}

@media (min-width: 768px) {
  .uploadContainer {
    height: auto;
    min-height: 600px;
    margin-bottom: 2rem !important;
    border-radius: 1vh;
  }
  .left-buttons {
    height: auto;
  }
}
@media (max-width: 1440px) {
  .uploadContainer {
    height: auto;
    min-height: 500px;
    margin-bottom: 2rem;
  }
  .left-buttons {
    height: auto;
    min-height: 400px;
  }
}
.left-buttons{
  flex-grow: 1;
}

.result-img-box{
  flex-grow: 3;
}

/* START : Change resize button */
.resize-popover-title{
  display: flex;
  flex-direction: column;
  padding: 1vh;
}
.resize-popover-title .title{
  margin: 0;
}
.chosen-size{
  font-size: 1.3vh;
  color: #A6ABC8;
}
.span-custom-dimensions{
font-size: 14px;
}
/* END : Change resize button */

@media (max-width: 768px) {
  .uploadContainer .actionButton::before {
    margin: 0 auto; /* Centers the icon */
    margin-bottom: 1vh;
    content: "";
    background-size: 25px;
    background-repeat: no-repeat;
    display: block;
    border-radius: 50%; /* Makes the icon round */
    background: 
        url(../../src/assets/images/icons/stack-alt-top.svg) center / 32px no-repeat;
    align-content: end;
    border: 1px solid rgba(172, 172, 172, 0.205);
    width: 35px;
    height: 35px;

    box-shadow: inset 0 0 20px 0 rgba(52, 152, 219, 0.466); /* Glowing shadow */
    transition: box-shadow 0.3s ease-in-out;
}
.uploadContainer .resize-action-btn::before {
  margin: 0 auto; /* Centers the icon */
  margin-bottom: 1vh;
  content: "";
  background-size: 25px;
  background-repeat: no-repeat;
  display: block;
  border-radius: 50%; /* Makes the icon round */
  background: 
      url(../../src/assets/images/icons/mobile-resize-icon.svg) center / 32px no-repeat;
  align-content: end;
  border: 1px solid rgba(172, 172, 172, 0.205);
  width: 35px;
  height: 35px;

  box-shadow: inset 0 0 20px 0 rgba(52, 152, 219, 0.466); /* Glowing shadow */
  transition: box-shadow 0.3s ease-in-out;
}
.uploadContainer .convertactionButton::before {
  margin: 0 auto; /* Centers the icon */
  margin-bottom: 1vh;
  content: "";
  background-size: 25px;
  background-repeat: no-repeat;
  display: block;
  border-radius: 50%; /* Makes the icon round */
  background: 
      url(../../src/assets/images/icons/mobile-converter-icon.svg) center / 32px no-repeat;
  align-content: end;
  border: 1px solid rgba(172, 172, 172, 0.205);
  width: 35px;
  height: 35px;

  box-shadow: inset 0 0 20px 0 rgba(52, 152, 219, 0.466); /* Glowing shadow */
  transition: box-shadow 0.3s ease-in-out;
}
.customActionButton{
  width: 35px;
  height: 35px;
  content: "";
  background: 
        url(../../src/assets/images/icons/mobile-customize-icon.svg) center / 32px no-repeat;
        border: 1px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;
}
.customConvertActionButton{
  width: 35px;
  height: 35px;
  content: "";
  background: 
        url(../../src/assets/images/icons/format-icon.svg) center / 27px no-repeat;
        border: 1px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;
}
.customActionButtonSecondary{
  width: 35px;
  height: 35px;
  content: "";
  background: 
        url(../../src/assets/images/icons/format-icon.svg) center / 27px no-repeat;
        border: 1px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;

}
.customActionButtonSecondary img{
  width: 30px;
  display: none;
}
.customConvertActionButton img{
  width: 30px;
  display: none;
}
.customActionButton img{
  width: 30px;
  display: none;
}
.span-custom-dimensions{
  display: none;

}
.action-container,.add-bg-controls{
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  z-index: 600;
  /*border-top-right-radius: 10vh;
  border-top-left-radius: 10vh;*/
  justify-content: center;
  padding: 1vh;
  border-top: 1px solid rgba(172, 172, 172, 0.205);
  /*
  border-radius: 3vh;
  box-shadow: inset 0 5px 20px rgba(172, 172, 172, 0.205);
  transition: box-shadow 0.3s ease-in-out;*/
    width: 98%;
  /*box-shadow: 0px -10px 15px -5px rgba(0, 0, 0, 0.3);*/
}

.uploadContainer .convertactionButton, .uploadContainer .resize-action-btn, .uploadContainer .actionButton,.uploadContainer .downloadButton{
  background: none;
  color: black;
  font-size: 0.6rem;
  font-weight: 600;
  display: block;
  text-align: center;
  margin-top: 10px; /* Adjust space between icon and text */
  max-width: min-content;
  padding: unset;
  margin-inline: 0.2rem;
  border: unset;
  margin-block: unset;
}
.uploadContainer .resetButton::after{
  visibility: visible;
  content:'New import'; 
  font-size: 0.6rem;
  font-weight: 600;
  line-height: unset!important;
}
.resetButton{
  
  line-height: unset!important;
}
.uploadContainer .downloadButton::before {
  content: "";
    background: 
        url(../../src/assets/images/icons/download-az.svg) center / 32px no-repeat;
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%; /* Makes the icon round */
    margin: 0 auto; /* Centers the icon */
    margin-bottom: 1vh;
    align-content: end;
    border: 1px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;
}
.uploadContainer .resetButton{
  visibility: hidden;
  font-size: 0px;
}
.uploadContainer .resetButton::before {
  margin-bottom: 1vh;
  visibility: visible;
  content: "";
  background: 
        url(../../src/assets/images/icons/brush.svg) center / 32px no-repeat;
        border: 1px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;
        box-shadow: inset 0 0 20px 0 rgba(219, 141, 52, 0.466); /* Glowing shadow */
    transition: box-shadow 0.3s ease-in-out;
}
.resize-controls .customBox{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: unset;
  margin-bottom: unset;
}
.customBox label {
  margin-left: unset;
  font-size: 0.6rem;
  font-weight: 600;
  /* font-weight: 600; */
}
.customBox{
  margin-inline: 1rem;
}



.upcontainer {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  resize: both;
  overflow: hidden;
  margin-top: 10vh;
  width: 100%;
  padding: 0;
  flex-direction: column;
}
.uploadContainer{
  padding: 0;
}
 #remove-background .footer-container, #add-background .footer-container, #resize-image .footer-container, #converter-image .footer-container{
  display: none;
}

.controls-section{
  border-top:unset;
}

.result-img-box{
  border-radius: 1vh;
    background-image: linear-gradient(45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), linear-gradient(-45deg, rgba(51, 51, 51, 0.1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%), linear-gradient(-45deg, transparent 75%, rgba(51, 51, 51, 0.1) 75%);
    background-size: 30px 30px;
    background-position: 0 0, 0 15px, 15px -15px, -15px 0;
    background-repeat: repeat;
}
.img-fluid{
  background: unset!important;
}

.alert{
  position: fixed;
  top: 10%;
  z-index: 500;
}

.upload-box {
  padding: 10%;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.uploadContainer {
  height: 100%;
  max-height: 100%;
  min-height: unset;
  margin-bottom: 0;
  display: unset;
}

.result-img-box {
  max-width: 100%;
  max-height: 100%;
}

.result-img-box{
  width: unset;
  height: unset;
  padding: unset;
  max-width: 100%;
  max-height: 100%;
}
.upload-image-box img{
  height: 100%;
  max-height: 100%;
}
.add-bg-controls .customBox{
  margin-inline: 0.2rem;
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  align-content: center;
  flex-direction: column;
  align-items: center;
  color: black;
  font-size: 0.6rem;
  font-weight: 600;
  color: black;
  font-size: 0.6rem;
  font-weight: 600;
  /* display: block; */
  text-align: center;
  margin-top: 10px;
  max-width: -webkit-min-content;
  max-width: min-content;
  padding: unset;
  border: unset;
  margin-block: unset;
}
.uploadContainer .customBox{
  margin-inline: 0.2rem;
}
.add-bg-controls .resize-header{
  display: flex !important;
  flex-direction: row!important;
  align-content: center;
  margin: 0;
}
.add-bg-controls{
  left: unset;
}
.add-bg-btn-container{
  margin: 0!important;
  display: flex;
}
.add-bg-controls button{
  margin: 0;
  margin-bottom: 0.4rem;
}

.customActionButton,.actionButton ,.downloadButton, .customConvertActionButton, .resize-action-button, .customActionButtonSecondary  {
  border-radius: 50%;
  padding: 0;
  box-shadow: unset;
}

.action-container label,.action-container .btn, .action-container .btn::after{
  font-weight: 500;
  color: #2E4454;
  font-size: 1.8vh;
}
.uploadContainer .actionButton::after,.uploadContainer .downloadButton:after, .uploadContainer .resize-action-button::after{
  visibility: visible;
  line-height: unset!important;
}
 .uploadContainer .convertactionButton, .uploadContainer .resize-action-btn, .uploadContainer .actionButton, .uploadContainer .actionButton,.uploadContainer .downloadButton,.uploadContainer #resize-box{
  font-size: 0;
  box-shadow: none;
}
.uploadContainer .resize-action-btn::after .actionButton::after{
  content:'Resize'; 
}

.uploadContainer .convertactionButton::after{
  content:'Converter'; 
}
// .uploadContainer .customActionButtonSecondary::after{
//   content:'Standars'; 
// }
.mobile-label{
  font-weight: 500;
    color: #2E4454;
    font-size: 1.8vh;
}

.uploadContainer .actionButton::after{
  content: attr(data-text);}
.uploadContainer .resize-action-btn::after{
  content: attr(data-text);
}
.uploadContainer .convertactionButton::after{
  content: attr(data-text);
}
.uploadContainer .downloadButton:after  {
  content: attr(data-text);
  font-weight: 500;
  color: #2E4454;
  font-size: 1.8vh;
}

.uploadContainer .resetButton:after  {
  content: attr(data-text);
}
.uploadContainer .resizeButton:after  {
  content:'Resize'; 
}
.CustomPlatformButton:after  {
  content:'Dimensions'; 
}
.standard-platform-dimensions-btn{
  display: flex;
    flex-direction: column;
}


.uploadContainer .downloadButton::before,.uploadContainer .actionButton::before,.uploadContainer .customActionButton, .customConvertActionButton, .uploadContainer .customActionButtonSecondary, .uploadContainer .convertactionButton::before, .uploadContainer .resize-action-btn::before   {
  width: 7vh !important;
  height: 7vh;
  margin-bottom: unset;
  border: 2px solid rgba(172, 172, 172, 0.205);
        border-radius: 50%;
        margin: 0;
}


.upcontainer .btn:hover{
  box-shadow: inset 0 5px 20px rgba(172, 172, 172, 0.205);
}
.add-bg-controls .actionButton::after{
  content: attr(data-text);
}
.add-bg-controls #colorPopover::before {
  content: "Color";
}
.add-bg-controls #backgroundPopover::before {
  content: attr(data-text);
}

.add-bg-controls #colorPopover::before,.add-bg-controls .actionButton::after, .add-bg-controls #backgroundPopover::before {
  font-weight: 500;
  color: #2E4454;
  font-size: 1.8vh;
}
.add-bg-controls #colorPopover,.add-bg-controls #backgroundPopover {
  font-size: 0;
}


.add-bg-controls > div.d-flex.flex-column.resize-header > div:nth-child(1) > div > div:nth-child(1) > button {
  background: url(../../src/assets/images/icons/img.svg) center / 32px no-repeat;
  border-right: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}

.add-bg-controls > div.d-flex.flex-column.resize-header > div:nth-child(2) > div > div:nth-child(1) > button {
  background: url(../../src/assets/images/icons/color.svg) center / 32px no-repeat;
  border-right: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}

.resize-popover-title{
  display: contents;
}

.resize-popover-title  .chosen-size{
  display: none;
}
.resize-action-btn{
 
  display: ruby !important;
  background-color: transparent !important;

  .resize-action-button:hover , .downloadButton:hover{
    background-color: none !important;
    -webkit-box-shadow:unset !important;
    box-shadow:unset !important
  }
}
.add-bg-controls #backgroundPopover::before,
.add-bg-controls #colorPopover::before {
  content: attr(data-text);
  font-weight: 500;
  color: #2E4454;
  font-size: 1.8vh;
  display: block;
  order: 2;
}

.add-bg-controls #backgroundPopover,
.add-bg-controls #colorPopover {
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-bg-controls #backgroundPopover button,
.add-bg-controls #colorPopover button {
  order: 1;
}
}

/* START : Change arraws and the left img actions style*/

.left-buttons .bottom-buttons div:nth-child(1) img,.left-buttons .bottom-buttons div:nth-child(2) img,.left-buttons .top-buttons img  {
  visibility: hidden;
}


.left-buttons .bottom-buttons div,.left-buttons .top-buttons div {
  width: 32px;
  height: 32px;
}
.left-buttons .bottom-buttons,.left-buttons .top-buttons {
  border: 2.5px dashed rgba(172, 172, 172, 0.205);
  border-radius: 8px;
  background-color: #ffffff77;
}
.left-buttons .bottom-buttons div:nth-child(1) {
  background: url(../../src/assets/images/icons/zoom-in-alt.svg) center / 26px no-repeat;
  border-bottom: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}

.left-buttons .bottom-buttons div:nth-child(2) {
  background: url(../../src/assets/images/icons/zoom-out-alt.svg) center / 26px no-repeat;
  border-top: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}

.left-buttons .top-buttons div:nth-child(1){
  background: url(../../src/assets/images/icons/jump-back-alt.svg) center / 26px no-repeat;
  border-right: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}

.left-buttons .top-buttons div:nth-child(2){
  background: url(../../src/assets/images/icons/jump-alt.svg) center / 26px no-repeat;
  border-left: 2px solid rgba(172, 172, 172, 0.205);
  border-radius: 100%;
}
.left-buttons .top-buttons {
  display: flex;
    flex-direction: row;
}
.left-buttons .bottom-buttons div:hover,.left-buttons .top-buttons div:hover{
  box-shadow: inset 0 5px 20px rgba(172, 172, 172, 0.205);
  box-shadow: inset 0 5px 20px rgba(172, 172, 172, 0.205);
}
.left-buttons {
  position: absolute;
  left: 5%;
  z-index: 100;
  height: 100%;
  min-height: unset;
  max-height: unset;
  padding-inline: unset !important;
}

/* END : Change arraws and the left img actions style*/

.upload-image-box {
  border-width: 3px ;
  border: solid;
  padding: 1vh;
  border-color: rgba(172, 172, 172, 0.205);
  border-radius: 1vh;
  background-color: rgba(172, 172, 172, 0.05);
  
}
.customActionButton {
  box-shadow: unset;
}
.rmvbg-notice {
  color: #5a626887;
    font-size: 13px;
    font-weight: 300;
    text-align:center;
}

.rmvbg-notice b {
  font-weight: bold;
  color: #5a6268;
}

.rmvbg-info-icon {
  width: 2.7vh;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-inline: 0.5vh;
  vertical-align: sub;
}
.tooltip-info {
  display: none; /* Hide the tooltip by default */
  position: absolute;
    bottom: 125%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #555555c9;
    color: #ffffffc2;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    z-index: 1;
    white-space: nowrap;
    font-size: 1.5vh;
}

.rmvbg-info-icon:hover .tooltip-info {
  display: block; /* Show tooltip on hover */
  z-index: 800;
}
.resize-popover-title .title {
  margin: 0;
  line-height: unset;
  height: fit-content;
}

img.selected-img-add-bg.buttonIcon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.select-img-add-bg-btn{
  overflow: hidden;
    position: relative;
    /* width: 100px; */
    /* height: 100px; */
    /* border: none; */
    cursor: pointer;
}

