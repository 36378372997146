.platform-selector-container {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.platform-selector-sidebar {
  width: 50%;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.platform-selector-sidebar-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color:  $warning;

}

.platform-selector-sidebar-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  margin-bottom: 4px;
  color: #333;
  font-size: 1em;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  transition: background 0.3s ease;
  cursor: pointer;
  background-color: transparent;
}

.platform-selector-sidebar-button:hover {
  background-color: #f1f1f1;
}

.platform-selector-sidebar-button.active {
  background: linear-gradient(90deg, #1E4E9D 0%, #0493E3 100%);
  color: white;

}

.platform-icon {
  width: 20px; /* Taille fixe pour éviter de changer lors de la sélection */
  height: 20px;
}

.platform-selector-content {
  flex-grow: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.platform-selector-content-type-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  
}

.platform-selector-content-type {
  // font-weight: bold;
  // color: #ff6b01;
  // margin-inline: 0.5rem
}

.platform-selector-content-dimensions {
  color: #888;
  text-align: left;
  margin-left: 8px;
}

.platform-selector-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.platform-selector-reset-button {
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.platform-selector-reset-button:hover {
  background-color: #f1f1f1;
}

.platform-selector-done-button {
  background-color: $primary;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.platform-selector-done-button:hover {
  background-color: #135abc;
}

.popover {
  --bs-popover-max-width: 45%,
}

.rectangle {
  width: 16px;
  height: 16px;
  top: -2567px;
  left: 57413px;
  gap: 0px;
  opacity: 1px;
  background: #ED6E1CCC;

}
.not-selected{
  display: flex;
}
.selected {
  background-color: hsl(0deg 0% 50% / 17%);
  border-radius: 10px;
  padding-inline: 0.15rem;
}
@media (max-width: 768px) {
  .platform-icon {
  width: 20px;
  height: 20px;
}
.platform-selector-sidebar{
  padding-right: 0;
}
.platform-selector-sidebar-button{
  padding:10px 1px;
  margin-bottom:0;
}
.platforme-name-style{
  font-size: 13px;
}
.platform-selector-sidebar-title{
font-size: 15.5px;
}
.platform-selector-content-type{
font-size: 14px;
}
.platform-selector-content-dimensions{
  font-size: 14px;
}
.platform-selector-reset-button {
  font-size: 14px;
}
.platform-selector-done-button{
  font-size: 14px;
}
.platform-selector-sidebar-button.active{
  padding: 3px;
}
}