.section {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.section.visible {
  opacity: 1;
}

.section-title-animated {
  transform: translateY(-20px);
  transition: transform 1s ease-in-out;
  opacity: 1;
}

.section-title-animated .visible {
  transform: translateX(0);
  opacity: 1;
}

.image-label {
  transform: translateY(-20px);
  transition: transform 1s ease-in-out;
  opacity: 0;
}

.image-label.visible {
  transform: translateX(0);
  opacity: 1;
}


.text-content-animated {
  transform: translateX(-20px);
  transition: transform 1s ease-in-out;
  opacity: 0;
}

.text-content-animated.visible {
  transform: translateX(0);
  opacity: 1;
}

.btn-animated {
  transform: translateX(-20px);
  transition: transform 1s ease-in-out;
  opacity: 0;
}

.btn-animated.visible {
  transform: translateX(0);
  opacity: 1;
}

.navbar-animated {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.navbar-animated.visible {
  transform: translateX(0);
  opacity: 1;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;


}

.bg-transparent {
  background-color: white !important;
}