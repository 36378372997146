.colorOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.colorBox {
  width: 65px;
  height: 65px;
  border-radius: 4px;
}

.resetButton {
  background: none;
  /*color: #1e4e9d;
  border: none;*/
  cursor: pointer;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 10px 30px;
  text-align: center;
}

.doneButton {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 20px;

  background: #1e4e9d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 30px;
}
.backgroundOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.backgroundBox {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-size: cover;
}

.backgroundOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.backgroundBox {
  width: 65px;
  height: 65px;
  border-radius: 4px;
  background-size: cover;
}
.resetButton{
  border-width: 2px;
  border-color: rgba(90, 98, 104, 0.151);
}
.resetButton,.doneButton{
  border-radius: 4vh;
  padding-inline: 3vh;
 }
 .bs-popover-auto{
  border-radius: 1vh;
  border: 3px dashed rgba(172, 172, 172, 0.205);
  // border-radius: 4vh;
  background-color: white;
 }
 .popover-header{
  color: rgba(90, 98, 104, 0.5294117647);
  text-align: center;
  background-color: rgba(172, 172, 172, 0.05);
  border-top-right-radius: 4vh;
  border-top-left-radius: 4vh;
  border-bottom: 3px dashed rgba(172, 172, 172, 0.205);
 }
 .popover-body{
  background-color: rgba(172, 172, 172, 0.05);
  border-bottom-right-radius: 4vh;
  border-bottom-left-radius: 4vh;
 }
 .colorBox img{
  opacity: 0.2;
 }
 .doneButton{
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
 }
 .popover-buttons {
  display: flex;
  justify-content: center;
}
.popover-header{
  background-color:  rgb(255, 255, 255);
}
.resetButton:hover,.doneButton:hover{
  transform: scale(1.1);
  border-width: 2px;
  border-color: transparent;
}
.modal-header{
  border-bottom:none;
}
.modal-footer{
  border-top:none;
  justify-content:center;

}
.modal-content {
  background-color:white;
  border-radius:1.5rem;
  padding: 2rem;
}
.modal-icon{
  display: flex;
  justify-content: center;
}
.span-modal-title{
  text-align: center;
}
.align-modal-title{
  display: flex;
  justify-content: center;
}
.span-modal-message{
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: center;

}
.modal-button-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
.primary-modal-button{
  border-radius: 4vh;
  padding-inline: 3vh;
  padding: 10px 30px;
}
@media (max-width: 768px) {
.modal-content {
  padding: 1rem;
  width: 80%;
}
.modal.show .modal-dialog{
  display: flex;
  justify-content: center;
}

.modal-body {
  padding: 0rem;
}
.modal-header
{
  padding-block: 0.5rem;
}
.span-modal-title{
font-size: 17px;
}
}