.profile-Label {
  margin-left: 10px;
}
.profile-icon {
  margin-top: -2px;
  width: 12%;
}

.profile-item {
  margin: 4px;
  .title {
    color: #575C76;
  }
  .value {
    font-size: 20px;
    font-weight: 400;
    color: #1e4e9d;
  }
}
.profile-subscription-title {
  color: gray;
}
.profile-subscription-value {
  font-weight: 600;
  margin: 10px;
}
.cardHeaderTitle {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.open {
  color: #1e4e9d !important;
}
.dropdown-toggle::after {
  display: none;
}

.profile-menu-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin: 5px 0px;
  padding: 5px 0px;
  width: 100%;
}

.profile-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: $warning;
}
.mobile-table-card {
  display: flex;
  justify-content: flex-start;
  margin: 10px 10px;
}
.mobile-table-card-title {
  font-size: 12px;
  width: 150px;
  font-weight: 700;
  line-height: 18px;
  color: #1e4e9d;
}
.mobile-table-card-value {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;

  color: #192a3e;
}
.mobile-profile-item {
  margin: 5px;
  border-radius: 12px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-direction: row;
  background: #1e4f9d0d;
  padding: 20px;
}
.mobile-details-box {
  margin-top: 8rem;
}
.text-gray {
  color: #575C76!important;

  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.profile-details-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1e4e9d;
}

.profile-details-sub-title {
  font-size: 20px;
 
}
.stats{
 font-size: 18px;
font-weight: 700;
line-height: 28px;
text-align: left;

}
.mobile-title{
   font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: $warning;
  
}
@media (max-width: 768px) {
  .profile-details-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #000000;
  }

  .profile-details-sub-title {
    font-size: 14px;

    line-height: 28px;
  }
  .profile-item {
    .title {
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
    }
    .value {
      font-size: 16px;
    }
  }
}

@media (max-width: 425px) {
  .mobile-profile-item {
    margin: 15px;
    padding: 10px;
  }
}
