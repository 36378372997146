.blog {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  // border-radius: 8px;
  background-color: #fff;
  box-shadow: 3px 13px 50px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  border-bottom-left-radius: none;
  border-bottom-right-radius: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog-content {
  max-width: 100%;
  flex: 2;
  padding: 1rem;
  text-align: justify;
}

.blog-content h1 {
  font-size: 2.75rem;
  font-weight: 700;
}

.blog-content h2 {
  font-size: 225%;
  font-weight: 700;
}

.blog-content p {
  margin: 10px 0;
  font-size: 143.75%;
  font-weight: 275;
  text-align: justify;
}

.image-blog-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  aspect-ratio: 4 /4;
}

.image-blog-details-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  aspect-ratio: 16 / 5;

}

.image-blog-content img {
  max-width: 100%;
  height: auto;
  /* Adjust aspect ratio for mobile, or set height explicitly */

  // min-width: 100%;
}

.bookmark-container {
  margin-left: auto;
}

.bookmark-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.blogs-container {
  display: flex;
}

.left-section {
  width: 90%;
}

.right-section {
  padding: 20px;
  margin-right: -0.4rem;
  box-shadow: 3px 13px 50px 0px rgba(0, 0, 0, 0.15);
  margin-top: -2%;
}

.left-card {
  margin-bottom: 20px;
}

.left-card .card-content {
  display: flex;
  align-items: center;
  aspect-ratio: 7/2;
  position: relative;
}

.left-card .card-content .image-container {
  position: relative;
  width: 50%;
  height: 100%;
}

.left-card .card-content .card-body {
  width: 50%;
  padding: 20px;
  text-align: left;
}

.left-card .card-body {
  flex: 1;
  padding-left: 10px;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 1rem;
}

.text-purple {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.footer-card {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: 768px) {
  .blog {
    flex-direction: column;
    padding: 0.5rem;
    // margin-top:-10%;
    align-items: center;
  }

  .blog-content h1 {
    font-size: 2rem;
  }

  .blog-content h2 {
    font-size: 1.75rem;
  }

  .blog-content p {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* Nombre de lignes à afficher */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
  }

  .blogs-container {
    flex-direction: column;
  }

  .right-section {
    display: none;
  }

  .left-section {
    width: 100%;
    // padding: 10px;
    // margin-top:15%;

  }

  .image-blog-content {
    width: 100%;
    aspect-ratio: 16/ 9;
    order: -1;
  }

  .blogs-container {
    margin-right: 0%;

  }

  .section-margin {
    margin-top: 20% !important;
    padding-inline: 2%
  }

  .heroTitle {
    font-size: 20px;
    // font-weight: unset;
    // margin: 20px 0;
  }

  .blog-card {
    margin-top: 20% !important;
  }

  // .left-card .card-content {
  //   flex-direction: column;
  // }
}

// .hero {
//   position: relative;
// }

.heroContent {
  // position: relative;
  // padding-left: 4rem;
  text-align: justify;
  margin-right: 0 !important;
}

// .text-content{
//   background-color: linear-gradient(90deg, #ED6E1C 0%, #1E4E9D 122.26%);
// }
.heroSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  margin-left: -1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.heroContent {
  flex: 3;
  margin-right: 20px;
}

.heroSection {
  margin-left: 0;
}

.heroImage {
  border-radius: 8px;
}

.heroTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.highlight {
  color: #ff6600;
}

.heroText {
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
}

.sidebar {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  font-size: 16px;
  margin: 10px 0;
}

.section-margin {
  margin-top: 10%;
}

.blog-section {
  max-width: 100%;
}