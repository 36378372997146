

$primary-color: #253058;
$text-color: #a6abc8;
// .pageWrapper {
//   min-height: 100vh;
// }

.contentArea {
  flex-grow: 1;
  padding-top: 2%;
}

.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.card {
  background: white;
  margin-bottom: 30px;
  box-shadow: $box-shadow;
}
.cardBackground {
  display: flex;
  padding: 10px;
  box-shadow: $box-shadow;
  justify-content: center;
}
.home-container {
 padding-inline: 3%;
}
.import-img {
  border: dashed #a6abc8;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.dropdown-menu {
  background: white;
  box-shadow: $box-shadow;
}

.cursor-pointer {
  cursor: pointer;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }

  &.md-box {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px;
  }
}

.btn {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.7rem 2.125rem 0.7rem 2.125rem;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
  font-weight: 500;
  // width: 36.933%;
  // height: 2.75rem;
}
.btndotsheader{
  background: transparent;
}
.btndotsheader:hover {
  background-color: transparent !important;
  background: transparent !important;
}
.btnheader {
  top: 1.25rem;
  left: 1.25rem;
  border: none;

  background: transparent !important;
  color: $black;
}
.btnheader:hover {
  color: #1e4e9d !important;

}

.button-group .btn {
  margin: 3px;
}

.btn:hover {
  color: #fff;
  // background: linear-gradient(90deg, #1e4e9d 0%, $warning 100%);
  // background:url(../../src/assets/images/icons/mobile-customize-icon.svg) 50% / 32px no-repeat !important;
  background-color: transparent;
}
.btn-white {
  border: 1px solid $border-color;
}

.simplebar-scrollbar:before {
  background: #a0a0a0;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.nav-link {
  font-size: 0.875rem;
  padding-top: 1rem;
  font-weight: 500;
}

.custom-dropdown-item .nav-link:hover {
  color: $light !important;
}

.sectionOne {
  display: flex; /* Modern flexbox */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space out items horizontally */
  background: transparent; /* Set a background color if needed */
  /* border-radius: 10px; */ /* Uncomment for rounded corners */
  padding: 4px;
  // padding-top: 4rem;
}

.sectionOne.image-left .image-content {
  order: -1;
  margin-right: 20%;
}

.sectionOne.image-right .image-content {
  margin-left: 20%;
}

.text-content {
  max-width: 50%;
  flex: 1;
}
.faq-text-content {
  max-width: 90%;
  flex: 1;
  margin-block: 1%;

}
.faq-text-content h2{
  font-size: 1.9rem;
  font-weight: 700;
}

.text-content h1 {
  font-size: 2.75rem;
  font-weight: 700;
}

.text-content h2 {
  font-size: 1.9rem;
  font-weight: 700;
}

.text-content p {
  margin: 10px 0;
  font-size: 1.125rem;
  font-weight: 275;
  color:#555555
}

.try-it-now {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.image-content {
  max-width: 50%;
  // flex: 1;
  transition: transform 0.3s ease;
  border-radius: 4px;
}

.image-content:hover {
  transform: scale(1.1);
}

body {
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 0;
  overflow: auto;
  max-width: 100%;
  background-size: cover;
  background-repeat: repeat;
  font-family: $font-family-sans-serif;  //background-image: url("/images/bg-gorella.jpeg");
}
.indexpage{
 
  margin-top:5%;
}
.upcontainer{
margin-top:6rem;
}
@media only screen and (max-width: 768px) {
  .sectionOne {
    flex-direction: column;
    padding: 0%;
    margin-bottom: 8%;
    flex-direction: column-reverse
  }
  .indexpage{
    padding-block: 15%;
  }
  .upcontainer{
    margin-right: 0px;
    }
  .text-content,
  .image-content {
    order: -1;
    max-width: 100%;
    margin-top: 0.5rem;
  }

  .text-content h1 {
    font-size: 2rem;
  }

  .text-content h2 {
    font-size: 1.4rem;
  }

  .text-content p {
    font-size: 1rem;
    text-align: justify;
  }

  .btn {
    font-size: 9px;
    padding: 10px 16px;
  }

  .sectionOne.image-right .image-content {
    margin-left: 0%;
  }

  body {
    padding-inline: 1%;
  }
}

/* Navbar style mode mobile */
@media only screen and (max-width: 768px) {
  .d-flex {
    // display: flex !important;
    // justify-content: center !important;
  }

  .navbar-left-side {
    display: contents;
  }
  .logo {
    width: 120px;
    height: auto;
  }

  .btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .nav-link {
    font-size: 16px;
  }

  .navbar {
    padding: 10px;
    min-height: 100%;
    display: inherit;
  }
  .three-image-section {
    padding: 0 !important;
    
  }
  .three-image-section-description-style{
    padding-inline: 0 !important;
    margin-top:-5% !important;
  }
  .section-title{
    margin-bottom:0;
  }

}
// @media (min-width: 1200px) {
//   h2, .h2 {
//     margin-top:10%;
//   }
// }

// Section two style
.three-image-section {
  text-align: center;
  padding: 20px;
  // background: linear-gradient(270deg, #223059 4.43%, #1E4E9D 91.22%);
}
.three-image-section-description-style{
  margin: 22px;
  font-size: 1.125rem;
  font-weight: 275;
  line-height: 30px;
  text-align: center;
  padding-inline: 20%;
  color: #555555;
}

.section-title {
  font-size:1.9rem;
  margin-bottom: 20px;
  font-weight: 700;
  // background: linear-gradient(90deg, #ed6e1c 0%, #1e4e9d 194.35%) text;
  color: black;
}

.image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-wrapper {
  flex: 1;
  margin: 0 10px;
  text-align: center;
  transition: transform 0.3s ease;
  
}

.image-wrapper:hover {
  transform: scale(1.1);
}

.image-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  font-size: 0.9375rem;
  padding: 10px;
}
@media (max-width: 768px) {
  .image-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;
  }

  .image-wrapper {
    min-width: 80%;
    scroll-snap-align: start;
  }

  .section-title {
    font-size: 1.4rem;
  }
  .swiper-slide{
  // width : fit-content !important;
  width : 80%;
  }
  .swiper-slide:last-child {
    width: 100%; /* La dernière image occupe toute la largeur */
  }
  
}

// Pricing section
.pricing-section {
  text-align: center;
}

.section-description {
  margin-bottom: 40px;
  font-size: 1.25rem;
  color: #333;
}

// .pricing-cards {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 2%;
//   flex-wrap: wrap;
// }
.pricing-cards {
  display: flex;
  margin-top: 2%;
  flex-wrap: wrap;
  justify-content: center;
}

.pricing-swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-card {
  display: flex;
  flex-direction: column;
  background: #f3eae8;
  padding: 1.5rem;
  border: 1px solid $warning;
  border-radius: $imgBorderRadius;
  width: 16.625rem;
  margin: 5px;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  transform-origin: bottom;
  position: relative;
  align-items: center;
  justify-content: center;
}

// change icons on price card
.price-card:nth-child(1):hover .icon-price-card {
  content: url("../../src/assets/images/icons/freeWhiteIcon.png");
}

.price-card:nth-child(2):hover .icon-price-card {
  content: url("../../src/assets/images/icons/buisnessacountIcon.png");
}

.price-card:nth-child(4):hover .icon-price-card {
  content: url("../../src/assets/images/icons/PremiumWhiteIcon.png");
}
.price-card:nth-child(3):hover .icon-price-card {
  content: url("../../src/assets/images/icons/blackgoldicon.png");
}

.icon-price-card {
  margin-bottom: 0.5rem;
}

.price-card:hover {
  transform: scaleY(1.05);
  border: none;
  // background-color: #1e4e9d;
  background: linear-gradient(359.73deg, #0493e3 -30.42%, #1e4e9d 99.67%);
}

.price-card:hover .price,
.price-card:hover .account-type,
.price-card:hover .per-month,
.price-card:hover .features li {
  color: #fff;
}

.price-card:hover .buy-button {
  background: #f97316;
}

.account-type {
  font-size: 1.375rem;
  // color: #f97316;
  background: linear-gradient(90deg, $warning 0%, #1e4e9d 194.35%) text;

  color: transparent;
  transition: color 0.3s ease;
}

.price {
  font-size: 2.25rem;
  color: #1e4e9d;
}

.per-month {
  margin-bottom: 20px;
  color: $lightgreen;
  font-size: 93.75%;
  transition: color 0.3s ease;
}

.features {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.navbar-left-side {
  list-style: none;
  // padding-right:4rem;
}

.features li {
  margin: 10px 0;
  transition: color 0.3s ease;
}

.included {
  color: $primary;
  font-size: 15px;
  text-align: left;
}

.not-included {
  color: #bbb;
  text-decoration: line-through;
  font-size: 15px;
  text-align: left;
}

.buy-button {
  margin-top: auto;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  color: #fff;
  // padding: 10px 20px;
  border: none;
  // width: 166px;
  // height: 44px;
  cursor: pointer;
}

.button-container {
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.Feature-icon {
  margin-right: 18px;
}

//pricing section mode mobile
@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }
  .pricing-swiper .swiper-slide {
    display: flex;
    justify-content: center;
    min-height:400px
  }
  
  .price-card {
    width: 100%;
    margin-bottom: 20px;
    min-height: 400px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .price {
    font-size: 2.1875rem;
  }

  .nav-link {
    font-size: 0.875rem;
  }
  .swiper-slide .swiper-slide-active{
    width: fit-content !important;

  }
}

.label-bold {
  font-weight: 500 !important;
  margin-left: 20px;
}
.upload-image-section {
  text-align: center;
  padding: 20px;
  // box-shadow: 3px 4px 2px 3px rgba(0, 0, 0, 0.25);
  // border: 2px dashed #ccc;
  border: 1px dashed rgba(166, 171, 200, 1);
}

.upload-container {
  margin-top: 1rem;
  max-width: 100%;
}

// header styles
.custom-dropdown-item:hover {
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
}

.custom-dropdown-item .icon-wrapper {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/AddBgBlueIcone.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: -7px;
}

.custom-dropdown-item:hover .icon-wrapper {
  background-image: url("../../src/assets/images/icons/AddBackgroundIconeWhite.png");
}

.custom-dropdown-item .icon-wrapper1 {
  display: inline-block;
  width: 24px;
  margin-bottom: -7px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/RemoveBgBlueIcone.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.custom-dropdown-item:hover .icon-wrapper1 {
  background-image: url("../../src/assets/images/icons/RemoveBgIcone.png");
}

.custom-dropdown-item .icon-wrapper2 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/OutilsIcon.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: -7px;
}

.custom-dropdown-item:hover .icon-wrapper2 {
  background-image: url("../../src/assets/images/icons/whiteOutilsIcon.png");
}
.icon-wrapper4 {
  display: inline-block;
  width: 24px;
  margin-bottom: -7px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/upgradeicon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.custom-dropdown-item .icon-wrapper5 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/blueResizeIcon.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: -7px;
}

.custom-dropdown-item:hover .icon-wrapper5 {
  background-image: url("../../src/assets/images/icons/resizeIcon.png");
}
.custom-dropdown-item .icon-wrapper6 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("../../src/assets/images/icons/blueconverterIcon.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: -7px;
}

.custom-dropdown-item:hover .icon-wrapper6 {
  background-image: url("../../src/assets/images/icons/convertwhiteIcon.png");
}
.upload-image-box {
  height: 100%;
  position: relative;
  justify-content: center;
  width: auto;
  display: flex;
  align-items: center;
  flex-grow: 3;
}


.upload-section
  {
    height: 30rem;
  position: relative;
  justify-content: center;
  width: 30rem;
  display: flex;
  align-items: center;
  flex-grow: 3;
}
.result-img-box {
  height: 30rem;
  padding: 25px;
  width: 30rem;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}
.upload-image-box img {
  /*width: 100%;*/
  height: 100%;
  max-height: 500px;

}

.customBox label {
  margin-left: 25px;
  font-weight: 600;
}
.left-box {
  max-width: 70%;
  flex-grow: 3;
}
.left-box h2 {
  font-size: 2rem;
  font-weight: 700;
}

.left-box p {
  margin: 10px 0;
  font-size: 143.75%;
  font-weight: 275;
}

.right-box {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  border-radius: 4px;
}
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10; /* Ensure it appears above other content */
}

.blurred {
  filter: blur(5px);
}
.popover-body {
  background: white;
}
.respnse-component{
  margin-top: 10%;
}

.message {
  margin-bottom: 1.5rem;

  h1 {
    font-size: 20px;
    color: $primary-color;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 20px;
    color: $text-color;
  }
}

@media (max-width: 768px) {
  .message {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      font-size: 16px;
    }

    p {
      font-size: 16px;
    }
  }
  .home-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .left-box h2 {
    font-size: 20px;
 


  }
  .left-box p {
    margin:  0;
    font-size: 18px;
    font-weight: 500;
}

.respnse-component{
  margin-top: 30%;
}

.customBox label {
  margin-left: 15px;
  font-weight: 600;
}

}
.dropdown-item{
  padding: 10px;
}

/* Masquer le Swiper sur les écrans plus grands */
@media (min-width: 769px) {
  .swiper-container {
    display: none;
  }
}

/* Masquer pricing card sur les petits écrans */
@media (max-width: 768px) {
  .desktop-view {
    display: none;
  }
}

/* Styles spécifiques pour les écrans mobiles de pricing card */
@media (max-width: 768px) {
  .pricing-section {
    padding: 0 15px;
    margin-block: 8%;
  }

  .pricesHeader {
    text-align: center;
  }

  .pricingTitle {
    font-size: 1.5rem;
  }

  .pricingDesc {
    font-size: 1rem;
  }
}

.section {
  padding-block: 4%;
}
.section-img-fluid{
  width: 100%;
  height: auto;
  border-radius: $imgBorderRadius;
}
@media (max-width: 768px) {
  .sectionOne.image-left .image-content {
    margin-right: 0%;
  }
  .home-container{
    padding-inline: 0;
  }
  .pricing-section{
    // margin-top: 0;
  }
}
#newsletter{
  border-radius: 0vh 0vh 0vh 2vh;
}
.three-image-section img{
  border-radius: $imgBorderRadius;
}
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.hidden {
  opacity: 0;
}
.profile-card-container{
  margin-inline-end: 0.75rem;
}
