.navbarItem {
    color: black !important;
    font-size: 0.9375rem;
    margin-inline: 1.5rem;
}

.navbar-animated {
    transition: height 0.3s ease;
}

.try-style {
    text-shadow: 0px 4px 16px rgba(237, 110, 28, 0.5);
}

.container-fluid {
    padding-inline: 3%;
}
.end {
color: $primary;
font-weight:600;
letter-spacing: 0.5px;
}
.start {
    color: $gray;
    font-weight:400;

    }

@media (max-width: 768px) {
    .navbarItem {
        padding-block: 1rem;
        // border-bottom: 1px solid rgba(221, 221, 221, 1);
    }

    .navbar-item {
        border-bottom: none;
    }

    .navbar-animated {
        transform: translateX(0);
        opacity: 1;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 1000;
        overflow: hidden;
    }

    .navbar-collapse {
        padding-top: 2rem;
        // padding: 0.75rem;
        // margin-top: 6%;
    }

    .dropdown-menu.show {
        display: contents;
    }

    .navbar-closed {
        height: auto;
    }

    .navbar-open {
        height: 100%;
    }

    .btn {
        padding-block: 0.7rem;
    }

    .dropdown-item {
        padding: 0 !important;
    }
    .header-input {
        width: 100%;
        height: 46px;
        top: 90px;
        left: 15px;
        border-radius: 20px;
        box-shadow: 0px 13px 50px 0px #ED6E1C26;
        border-color: transparent;
        // margin-inline: 4%;
        padding-inline: 4%;
    }
    
    .input-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 4%;
    }
    
    .input-header .start-box {
        flex-grow: 1;
        text-align: left;
        color: $gray;
        font-weight:400;
        font-size:1.125rem
    }
    
    .input-header .end-box {
        text-align: right;
        white-space: nowrap;
        color: $primary;
        font-weight: 600;
    }
    .navbar-mobille{
        margin-block: 15%;
    }
    
}

@media (min-width: 700px) and (max-width: 1024px) {
    .navbar-animated.visible {
        top: 0;
        width: 100%;
    }

    .indexpage {
        margin-top: 15%;
    }

    .navbar {
        margin-top: 0 !important;
        position: relative; /* Or static depending on your layout */
        z-index: 999;
        height: auto;
    }

    .navbarItem {
        font-size: 0.9375rem;
        margin-inline: 1rem;
    }

    .navbar-animated {
        background-color: #fff;
    }

    .btndotsheader {
        padding: 1rem;
    }
}

@media (min-width: 700px) and (max-width: 1400px) {
    .indexpage {
        margin-top: 12%;
    }
}
@media (min-width: 768px){
    .navbar-expand-md .navbar-collapse{
        justify-content:center !important;
        // display:none!important
    }
}
.a{
    color: var(--bs-warning-text-emphasis) !important;
}

.header-content{
    border-radius: 0 0 85% 85% / 30%;
    padding: 2vh;
    border: 0.5vh solid rgba(172, 172, 172, 0.1);
}
.btn,.navitem-section,.icon-wrapper {
    transition: transform 0.7s ease;
  }

.btn:hover,.navitem-section:hover,.icon-wrapper:hover{
    transform: scale(1.05);
}
.btn:active,.navitem-section:active,.icon-wrapper:active {
    transform: scale(0.7); 
  }
  nav ul li a:hover {
    text-shadow: 0px 4px 16px rgba(52, 152, 219, 0.8);
  }

  @media (max-width: 768px){
    .header-content{
        padding: 1vh;
    }
}
.navbar-open .header-content{
    border-radius: 0;
}