.newsletter-container {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.newsletter-sub {
  width: 100%;
}
.newsletter-header {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
}
.newsletter-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.1em;
  text-align: left;
  background: linear-gradient(90deg, $warning 0%, #1e4e9d 194.35%) text;

  color: transparent;
  transition: color 0.3s ease;
}

.newsletter-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}
.newsletter-subscribe {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
}
.newsletter-button {
  height: 43px;
  margin-top: 5px;
  background: -webkit-linear-gradient(left, #1e4e9d 0%, #0493e3 100%);
  background: -moz-linear-gradient(left, #1e4e9d 0%, #0493e3 100%);
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: -5px;
  border-radius: 0px 2vh 2vh 0px;
}
.newsletter-img-desc {
  display: flex;
  flex-wrap: nowrap;
}
.newslater-blog{
  padding-block: 5%;
}
@media (max-width: 768px) {
  .newsletter-desc {
    font-size: 14px;
  }
  .newsletter-container {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .newsletter-img-desc {
    flex-wrap: wrap;
  }
  .newsletter-title {
    font-size: 30px;
  }
  .newslater-blog{
    padding-block: 4%;
  }
}

@media (max-width: 425px) {
  .newslater-blog{
    padding-block: 3%;
  }
}

.align-items-stretch {
  align-items: stretch;
  display: flex;
}

#newsletter{
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  flex: 1; /* Allows the input to take available space */
  height: 42px;
}

.newsletter-button {
  padding: 0 20px; /* Adjust horizontal padding as needed */
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  display: inline-block;
}

@media (max-width: 768px) {
  .form-input,
  .newsletter-button {
    font-size: 14px;
  }
}