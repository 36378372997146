.customBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  width: 100%;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  background-color:"transparent"
}
.customActionButton {
  background: linear-gradient(45deg, #ed6e1c, #ffb65a);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
} 

.red-cancel {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
  padding: 0;
}

.buttonIcon {
  width: 36px;
  height: 36px;
}

.downloadButton {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.7rem 2.125rem 0.7rem 2.125rem;
  margin: 0.625rem 0;
  border: 1px solid $warning;
  background: white;
  color: $warning;
  font-weight: 500;
}

.actionButton {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.825rem 2.125rem 0.7rem 2.125rem;
  margin: 0.625rem 0;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
  font-weight: 500;
}

.actionButton:hover {

  background-color: linear-gradient(90deg, #1e4e9d 0%, $warning 100%);

}
.resize-action-btn {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.825rem 2.125rem 0.7rem 2.125rem;
  margin: 0.625rem 0;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
  font-weight: 500;
}

.resize-action-btn:hover {

  background-color: linear-gradient(90deg, #1e4e9d 0%, $warning 100%);

}
.customActionButtonSecondary {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.825rem 2.125rem 0.7rem 2.125rem;
  margin: 0.625rem 0;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
  font-weight: 500;
}

.customActionButtonSecondary:hover {

  background-color: linear-gradient(90deg, #1e4e9d 0%, $warning 100%);

}
.convertactionButton {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 200;
  padding: 0.825rem 2.125rem 0.7rem 2.125rem;
  margin: 0.625rem 0;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
  font-weight: 500;
}

.convertactionButton:hover {

  background-color: linear-gradient(90deg, #1e4e9d 0%, $warning 100%);

}

.downloadButton:hover {
  color: #fff;
  background: linear-gradient(45deg, $warning, #ffb65a);
}

.popover-buttons {
  display: flex;
  justify-content: flex-end;
}

.collapse_btn {
  color: "#1E4E9D";
  font-size: "20px";
  cursor: "pointer";
  width: "25px";
}
.btn:hover {
  border-color: none !important;
}

@media (max-width: 768px) {
  .btn{
    background-color: #3498db;
  }
  .btn-container{
    display: flex;
    justify-content: center;
  }


  .google-text-btn {
    font-size: 1rem;
  }
}
@media (max-width: 1090px) and (min-width: 768px) {
  .hide-on-tablet {
    display: none;
  }
}

.btn-upgrade {
  background: #80808085;
  padding-block: 4%
}

.btn-logout {
  padding-block: 4%
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  color: white;
}

.btn{
  // padding: 15px 30px;
            font-size: 14px;
            border-radius: 2vh;
            cursor: pointer;
            box-shadow: 0 0 20px rgba(52, 152, 219, 0.6); /* Glowing shadow */
            transition: box-shadow 0.3s ease-in-out;
}
.btndotsheader {
  padding: unset;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: unset; /* Glowing shadow */
            transition: box-shadow 0.3s ease-in-out;
            background-color:unset;
}
.newsletter-button{
  font-size: 14px;
}
.upload-box{
  background-color: rgba(172, 172, 172, 0.05);
}
.downloadButton {
  box-shadow: 0 0 20px #ffb55a36;
}

.navbar-left-side .btn{
  box-shadow: unset;
}

.google-btn{
border-radius: 4px;
border: 1px 0px 0px 0px;
border: 1px solid rgba(30, 78, 157, 1);
background-color:transparent;
width:100%;
margin-block:2%
}
.google-btn-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.google-icon{
  margin-inline: 2%;
}
.google-costume-line{
top: 460.49px;
left: 803.65px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
border: 1px solid rgba(87, 92, 118, 1);

}
.hr-container{
 width: 40%;

}
.hrr-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 2%;
}
.btn-option-secondary{
    background: transparent;
    color: $warning;
    box-shadow: none;
    border: 1px solid $warning;
    font-size: 14px;

}
.btn-option-secondary:hover{
  border: none;
}
.language-btn{
  width: 100%;
  color: black;
  background: transparent;
  border: 1px solid #ed6e1c;
}
.language-btn:hover{
  transform: scale(1.05) !important;
  color: black;
  border: 1px solid #ed6e1c;
  background-color:transparent;
}
.language-btn:focus-visible {
  background-color:transparent;
  border: 1px solid #ed6e1c;
  color: black;
}
.language-btn:active {
  background-color:transparent !important;
  border: 1px solid #ed6e1c !important;
  color: black !important;
}
.btn-upgrade {
//  background-color:var(--bs-btn-active-bg) !important;
background-color: #80808085 !important;
}