/*** Loader style ***/

.loader-overlay {
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #000000cf;
  border-radius: 5px;
  animation: fadeOut 3s ease-in-out infinite;
}

.loader-squares {
  position: relative;
  width: 60px; // Adjust width to match the squares
  height: 100px; // Adjust height to match the squares
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 columns for the 'P'
  grid-gap: 5px; // Space between squares
}

.square {
  width: 15px; // Square width
  height: 15px; // Square height
  background-color: $warning; // You can set this color or a specific color
  animation: float 1s ease-in-out alternate-reverse infinite, fadeOut 1.5s ease-in-out infinite;
  border-radius: 2px;
}

// Define positions for the squares to form P
.square:nth-child(12) { position: absolute; animation-delay: 0.1s;
  left: -10px;
  top:-5px;
  background-color: $primary;
  border-color: rgb(0 0 0 / 0%);
  width: 10px;
  height: 10px;
}  
.square:nth-child(13) { position: absolute; animation-delay: 0s;  
  left: -5px;
  top:-0px;
  width: 10px;
  height: 10px;
  background-color: $primary;
} 
.square:nth-child(1) { grid-area: 1 / 1; animation-delay: 0s; border-top-left-radius: 5px; }  // Top Left
.square:nth-child(2) { grid-area: 1 / 2; animation-delay: 0.1s; }  // Top Middle
.square:nth-child(3) { grid-area: 1 / 3; animation-delay: 0.2s; border-top-right-radius: 15px;}  // Top Right
.square:nth-child(4) { grid-area: 2 / 1; animation-delay: 0.3s; }  // Middle Left
.square:nth-child(5) { grid-area: 2 / 2; animation-delay: 0.4s; display: none;}  // Middle Middle
.square:nth-child(6) { grid-area: 2 / 3; animation-delay: 0.5s; }  // Middle Right
.square:nth-child(7) { grid-area: 3 / 1; animation-delay: 0.6s; }  // Bottom Left
.square:nth-child(8) { grid-area: 3 / 2; animation-delay: 0.7s; }  // Bottom Middle
.square:nth-child(9) { grid-area: 3 / 3; animation-delay: 0.8s; border-bottom-right-radius: 15px;  }  // Bottom Middle
.square:nth-child(10) { grid-area: 4 / 1; animation-delay: 0.8s; }  // Bottom Middle
.square:nth-child(11) { grid-area: 5 / 1; animation-delay: 0.10s; border-bottom-right-radius: 15px;
  border-bottom-left-radius: 5px;
}  // Bottom Middle

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(-10px); }
}

@keyframes fadeOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

/*** END Loader style ***/
