/* components/common/Form.css */

.cardForm {
  margin-bottom: 30px;
  // box-shadow: $box-shadow;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
}

.custom-form {
  background-color: transparent;
  border-radius: 8px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.custom-name-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.form-checkbox-container {
  display: flex;
  align-items: center;
}

.form-checkbox1 {
  margin-right: 0.5em;
}

.form-checkbox-label {
  margin: 0;
  color: $gray;
  font-size: 0.875rem;
  padding: 1rem;
}

.form-input,
.form-checkbox,
.form-radio-input,
.form-file-input,
.form-date-input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid rgba(30, 78, 157, 0.25);
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: $gray;
}

.form-input::placeholder,
.form-checkbox::placeholder,
.form-radio-input::placeholder,
.form-file-input::placeholder,
.form-date-input::placeholder {
  color: $gray;
}

.form-checkbox {
  width: auto;
}

.form-radio {
  display: inline-flex;
  margin-right: 3%;
}

.form-error {
  color: #ff0000;
  font-size: 12px;
}

.form-submit-button {
  width: 100%;
  padding: 10px 40px;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  border-radius: 0.25rem;
  color: white;
  font-size: 16px;

  cursor: pointer;
  margin-top: 1rem;
}

.form-submit-button:hover {
  // background-color: #0056b3;
  background: linear-gradient(90deg, #1e4e9d 0%, #0493e3 100%);
  border: none;
  color: white;
}

.form-reset-button:hover {
  background-color: #dee5eb;
  transform: scale(1.05);
}

.form-reset-button {
  width: 30%;
  padding: 10px;
  background-color: #ddd;
  border: none;
  border-radius: 0.5rem;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-group-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  min-width: 200px;
  margin-bottom: -0.6rem;
}

.custom-label-input {
  color: #0056b3;
  font-size: 14px;
}

.form-file-input:focus {
  outline: none;
  border-color: #1e4e9d;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-date-input:hover,
.form-file-input:hover,
.form-control:hover .form-input:hover,
.form-checkbox:hover,
.form-radio-input:hover {
  border-color: $primary;
}

.form-date-input:focus,
.form-control:focus,
.flag-dropdown:focus .form-file-input:focus,
.form-input:focus,
.form-checkbox:focus,
.form-radio-input:focus {
  outline: none;
  // border-color: #1e4e9d !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media only screen and (max-width: 600px) {
  .custom-form {
    max-width: 100%;
  }

  .form-label {
    font-size: small;
  }

  .custom-name-input {
    padding: 8px;
    font-size: 12px;
  }

  .form-input,
  .form-checkbox,
  .form-radio-input,
  .form-file-input,
  .form-date-input {
    padding: 8px;
    font-size: small;
  }

  .form-checkbox-label {
    font-size: small;
  }

  .form-submit-button,
  .form-reset-button {
    padding: 10px 40px;
    font-size: small;
  }

  .form-group-row {
    gap: 4px;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  height: unset !important;
  border: 1px solid rgba(30, 78, 157, 0.25) !important;
  color: $gray;
}

.react-tel-input .flag-dropdown {
  border: 1px solid rgba(30, 78, 157, 0.25) !important;
}

.react-tel-input {
  margin-top: 0.6rem;
  margin-bottom: 0.9rem;
}

.css-13cymwt-control {
  margin-block: 0.95rem;
  border: 1px solid rgba(30, 78, 157, 0.25) !important;
}

.css-1jqq78o-placeholder {
  color: rgba(87, 92, 118, 1) !important;
  font-size: 14px;
}

.css-13cymwt-control:focus {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important;
  outline: none !important;
}

.css-t3ipsp-control {
  border-color: transparent !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important;
  margin-block: 1rem !important;
}

.css-1dimb5e-singleValue {
  font-size: 14px !important;
}

.forgot-password-text {
  text-align: center;
  font-weight: 500;
}
.resize-image-form-input{
  display: flex;
  gap: 10px;
}