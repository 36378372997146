.slider-box {
  height: 60px;
  margin-bottom: 20px;
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: orange;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: orange;
  cursor: pointer;
  border-radius: 50%;
}

.slider-label {
  font-size: 16px;
  margin-bottom: 8px;
}

.slider-container {
  margin: 20px 0;
}

.slider-value {
  font-size: 16px;
  color: #888;
  margin-left: 10px;
}
.sliderValue{
    color:   #A6ABC8;
    ;
}
.slider input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(to right, orange 0%, orange var(--value, 50%), #ccc var(--value, 50%), #ccc 100%);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 15px;
    width: 15px;
    background-color: #f50;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
  }
.slider input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #f50;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
  }