.card-blog-title {
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // -webkit-line-clamp: 2; /* Limit to 3 lines */
    max-height: 4.5em;
    line-height: 1.5em;
    font-size: 1rem;
}

.card-text {
    text-align: justify;
    font-weight: 300;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 4.5em;
    line-height: 1.5em;
    font-size: 0.875rem;
}

.blogs-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.blog-card-wrapper {
    flex: 0 0 18rem;
    margin: 10px;
    border: 1px solid rgba(237, 110, 28, 1);
    border-radius: $imgBorderRadius;
    contain: content;
}

.card-blog {
    height: 100%;
    width:21rem !important;
}

.card-blog-body {
    display: flex;
    flex-direction: column;
}

.footer-blog-card {
    color: transparent;
    background-color: transparent;
    padding: 0;

}

.card-date {
    font-size: 13px;
    font-weight: 300;
}

.card-footer {
    background-color: #00000005
}

.blog-card-section-description-style {
    text-align: justify;
    font-size: 1.125rem;
    font-weight: 275;
}

.blog-image-card-section-description-style {
    margin: 22px;
    font-size: 1.125rem;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    padding-inline: 20%;
    color: #555555;
}

.card-section-title {
    font-size: 1.9rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: black;
}

.blog-card-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 5%;
}

.card-image-container {
    aspect-ratio: 16/9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;

}
.aspect-ratio-style{
    aspect-ratio: auto ;
    padding-block:2%;
}

.card-image {
    height: 100%;
}
.card-blog{
  border-radius: $imgBorderRadius;
}
@media (max-width: 768px) {
.card-blog{
    width:90% !important;
    border: 1px solid rgba(237, 110, 28, 1);
    contain: content;
}
}
.item-card-end{

    justify-content: space-between;
}