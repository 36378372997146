.question {
    display: flex;
    justify-content: space-between;
    padding: 8px 0 8px 0;
    font-weight: 500;
    font-size: 1.125rem;
}

.faqimage {
    padding-top: 5%;
    margin: 0;
    width: 100%;
}

.my-custom-line {
    width: 100%;
    height: 3px;
    padding: 0;
    gap: 10px;
    background-color: #D7DEF0;
    border: none;
}

.faq-card {
    margin-block: 7%;
}

@media (max-width: 768px) {
    .faqimage {
        margin: 0 auto;
        margin-top: 5%;
    }

    .faq-text-content {
        padding: 1rem;
    }

    .my-custom-line {
        width: 100%;
    }
}