.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 8px 15px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #ECE7E2;
    cursor: pointer;
    color: $gray;
  }
  
  .pagination-button.active {
    background-color: $primary;
    color: white;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  