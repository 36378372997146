.responsive-circle {
    position: absolute;
    top: 100%;
    left: 0%;
    transform: translate(0, -80%);
    width: 10rem;
    z-index: 5;
  }
  .responsive-circle-50 {
    width: 7rem;

  }
 
  .text-style{
  color: $primary;
  position:relative;
  }
  .inline-title
  {
   position: relative;
    display: inline-block;
  }
  
  .rate-title{
    color: #A6ABC8;
    font-size: 14px;
    font-weight: 600;

  }
  .display-flex{
    display: flex;
  }
  @media (max-width: 1024px) {
    .responsive-circle {
      width: 8rem;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-circle {
      width: 7rem;
    }
  }
  
  @media (max-width: 480px) {
    .responsive-circle {
      width: 7rem;
    }
  }
  
  @media (max-width: 964px) and (min-width: 823px) {
    .responsive-circle {
      top: 50%;
    }
  }
  
  @media (max-width: 1793px) and (min-width: 1612px) {
    .responsive-circle-100 {
        top: 100%;
      }
  }
  @media (max-width: 1793px) and (min-width: 1612px) {

    .responsive-circle-100 {
        top: 100%;
      }
  }
  @media (max-width: 768px) {
  .pricingTitle  .responsive-circle{
      top: 50%;
    }
  
  }
  .google-text-btn{
    font-weight: 600;

  }

  @media only screen and (max-width: 768px) {
    .title-page-style{
      display: flex !important;
      justify-content: flex-start !important;
      margin-top: 4%;
    }
    .title-h2-style {
      display: flex !important;
      justify-content: flex-start !important;
      display: none !important;
    }
    .fs-34{
      font-size: 23px !important;
        }
        .fs-22{
          font-size: 20px !important;
          font-weight: 300 !important;
        }
  }
  .title-page-style{
    display: flex;
    color: #000000cc;
    justify-content: center;
    padding:0% 0% 0% 0%;
  }

  .fs-34{
font-size: 36px ;
  }
  .fs-22{
    font-size: 22px ;
    font-weight: 300 !important;
  }
  .title-h2-style {
    display: flex;
    justify-content: center;
    color: #575c76a1;
  
  }
  .mt-30{
    margin-top: 30%;
  }