.privacy-container {
  margin: 0px 30px 30px 30px;
  padding: 30px;
}
.privacy-title-box {
  display: flex;
  justify-content: center;
  width: 100%;
 
}

.privacy-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
  padding: 10px 40px;
  width: fit-content;
  background-color: #ed6e1c33;
  border-radius: 10px;
  margin: 10px;
}
.desc-box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px;
  p {
    width: 50%;

    font-size: 19px;
    font-weight: 300;
    line-height: 25.84px;
    text-align: center;
  }
}

.date-update {
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
}
.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
 
}
.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
}
.first-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
}
.sub-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
}
.box-margin{
  margin-top: 20px;  
}

@media (max-width: 768px) {
  .desc-box {
    p {
      width: 100%;
      margin-right: 10px;
    }
  }
  .privacy-container {
    margin: 10px;
    padding: 10px;
  }

  .privacy-title {
    font-size: 20px;
  }
}
