 /* START : Animation  */ 
 .header-nav .smoke-effect{
    z-index: 0;
    position: absolute;
    transform: rotate(180deg);
    top: -23vh;
    left: 0;
    width: 100%;
    
  overflow: hidden;
}
.footer-container .smoke-effect{
  z-index: 0;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  overflow: hidden;
  bottom: 0;
}
.smoke-effect svg {
    width:100%;
    overflow:visible;
  }
  body {
    
    --col-deepblue: rgba(131, 121, 121, 0.89);
    --col-deepcolor: rgba(172, 172, 172, 0.1);
    --col-deepfootcolor: #1b55a4;
  }

  .smoke-effect .wave {
    
    animation: wave 10s linear;
    animation-iteration-count:infinite;
    fill: var( --col-deepcolor);
    z-index: 0;
  }
  .smoke-effect .drop {
    fill:  var(--col-deepcolor);
    animation: drop 10s linear infinite normal;
    stroke-width:0.5;
    transform: translateY(25px) ;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  }
  .smoke-effect .drop1 {
    
  }
  .smoke-effect .drop2 {
    animation-delay: 3s;
    animation-duration:3s;
  }
  .smoke-effect .drop3 {
    animation-delay: -2s;
    animation-duration:3.4s;
  }
  .smoke-effect .drop4 {
    animation-delay: 1.7s;
  }
  .smoke-effect .drop5 {
    animation-delay: 2.7s;
    animation-duration:3.1s;
  }
  .smoke-effect .drop6 {
    animation-delay: -2.1s;
    animation-duration:3.2s;
  }
  .smoke-effect .gooeff {
      filter: url(#goo);
  }
  .smoke-effect  #wave2 {
    animation-duration:5s;
    animation-direction: reverse;
    opacity:.2;
  }
  .smoke-effect  #wave1 {
    animation-duration:7s;
    opacity:.3;
  }
  .smoke-effect #wave3 {
    animation-duration: 8s;
    opacity:.4;
  }
  .smoke-effect #wave4 {
    animation-duration: 6s;
    opacity:.1;
    animation-direction: reverse;
  }
  @keyframes drop {
    0% {
      transform: translateY(25px); 
    }
    30% {
      transform: translateY(-10px) scale(.1);
    }
    30.001% {
      transform: translateY(25px) scale(1); 
    }
    70% {
      transform: translateY(25px); 
    }
    100% { 
      transform: translateY(-10px) scale(.1);  
    }
  }
  @keyframes wave {
    0% {transform: translateX(-0%);}
    50% {transform: translateX(-100%);}
    100% {transform: translateX(-0%);}
  }
  .smoke-effect .gooeff .drop{
    background-color: #4478e3;
    color: #4478e3;
  }

  .header-nav .drop{
    display: none;
  }
  .footer-container .smoke-effect .wave ,.footer-container .smoke-effect .drop {
    fill: var(--col-deepfootcolor);  
    }
 .smoke-effect{
  pointer-events: none;
 }

 .footer-section,.footer-links,.header-content {
  z-index: 3;
 }

 .homepage-background{
  position: fixed;
  width: 100%;
  left: 0;  
  top: 0;
  height: 100%;
  pointer-events: none;
  padding-inline: 5%;
  display: none;
 }
 
 .homepage-background-content{
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #4478e3;
  background-image: 
  linear-gradient(45deg, rgba(0, 0, 255, 0.07) 25%, transparent 25%), 
  linear-gradient(-45deg, rgba(0, 0, 255, 0.07) 25%, transparent 25%), 
  linear-gradient(45deg, transparent 75%, rgba(255, 166, 0, 0.07) 75%), 
  linear-gradient(-45deg, transparent 75%, rgba(255, 165, 0, 0.07) 75%);  background-size: 30px 30px;
  background-position: 0 0, 0 15px, 15px -15px, -15px 0;
  background-repeat: repeat;
 }
