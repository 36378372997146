.use-case-drop-dowbmenu{
    inset: 28% auto auto 0px !important; 
  }
  .setting-drop-dowbmenu{
    inset: 10% auto auto 0px !important; 
  }

  // .dropdown-profile{
  //   width: 100% !important;
  // }
  .header-input-dropdown-profile{
    box-shadow: 0px 13px 50px 0px #ED6E1C26;
    top: 90px;
    left: 15px;
    border-radius: 20px 0px 0px 0px;
    opacity: 10px;
    display: flex;
    justify-content: center;
  }
  .input-header .start-box {
    flex-grow: 1;
    text-align: left;
    color: $gray;
    font-weight:400;
    font-size:1.125rem
}

.header-input-dropdown-profile .input-header .end-box {
    text-align: right;
    white-space: nowrap;
    color: $primary;
    font-weight: 600;
}

